body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

.spin {
  $spin-speed: 0.85s;
  -webkit-animation: fa-spin $spin-speed infinite linear;
  -moz-animation: fa-spin $spin-speed infinite linear;
  -o-animation: fa-spin $spin-speed infinite linear;
  animation: fa-spin $spin-speed infinite linear;
}
